<template>
  <div v-if="product" class="full-product">
    <div class="container">
      <div class="full-product__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link to="catalog" class="crumbs-link page-catalog-link">
          {{ $t("discountCatalog") }}
        </locale-router-link>
        <a class="crumbs-link full-product-link">{{
          product.attributes.name
        }}</a>
      </div>

      <div v-if="!isShowLoader" class="full-product__content">
        <div class="full-product__descr-wrapper">
          <div class="full-product__date">
            <div class="full-product__stock">
              {{ getDiscount(product) }}
              <img
                v-if="product.attributes.best_price"
                class="full-product__stock-icon"
                src="@/assets/img/icons/best_price-icon-black.svg"
                alt=""
              />
            </div>
            <div v-if="!$isMobile()" class="full-product__time">
              {{ $t("validUntil") }} {{ getDate(product) }}
            </div>
            <div v-else class="full-product__time-mobile">
              {{
                $i18n.locale === "ru"
                  ? `до ${getDateMob(product)} `
                  : `${getDateMob(product)} gacha`
              }}
              <!-- TODO cut date-->
            </div>
          </div>
          <div class="full-product__title-wrapper">
            <h1 class="full-product__title">
              {{ product.attributes.name }}
            </h1>
            <div class="full-product__descr">
              {{ $t("stocksInfo") }}<br />{{ $t("stocksInfo2") }}
            </div>
          </div>
          <div class="full-product__prices">
            <div
              class="full-product__price-old"
              :class="{ 'best-price': product.attributes.best_price }"
            >
              {{ getBestPrice(product) }}
            </div>
            <div class="full-product__price-new">
              {{ product.attributes.final_price }}
            </div>
          </div>
        </div>

        <div class="full-product__img">
          <img :src="imageUrl" alt="Product image" width="304" height="304" />
          <div
            v-if="product.attributes.translation_kaz && $i18n.locale === 'ru'"
            class="full-product__translate"
          >
            <span class="full-product__translate-this"> это </span>
            <span class="full-product__translate-word">{{
              product.attributes.translation_kaz
            }}</span>
          </div>
        </div>
      </div>
      <div v-else class="loader__container">
        <div class="loader"></div>
      </div>

      <div v-if="filteredShops" class="full-product__stores">
        <h2 class="full-product__stores-title">
          {{ $t("storesInSale") }}
        </h2>
      </div>
    </div>

    <shops-map
      :is-show-title="false"
      :is-show-shop-types="true"
      :isShowList="false"
      v-if="product.attributes.shops.data"
      :shops-list="product.attributes.shops.data"
    />

    <weekly-products-list v-if="product" :exclude-product="product.id" />

    <locale-router-link to="career" tag="section" class="banner-vacancy">
      <img
        class="banner-text-img"
        src="@/assets/img/icons/banner-text.svg"
        alt="banner text"
      />

      <div class="container">
        <div class="banner-vacancy__content">
          <div class="banner-vacancy__header">
            <img
              class="banner-wings"
              src="@/assets/img/icons/banner-wings.svg"
              alt="image"
              width="64"
              height="67"
            />
            <h2>
              {{ $t("buildCareer") }}<span> {{ $t("leaveRequest") }}</span>
            </h2>
          </div>
          <button class="btn-gray">{{ $t("submitApplication") }}</button>
        </div>
      </div>
    </locale-router-link>
  </div>
  <div v-else class="loader__container">
    <div class="loader"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "@/helpers";
import { getProductById } from "@/api/products";

export default {
  name: "FullProduct",
  components: {
    WeeklyProductsList: () =>
      import("@/components/products/WeeklyProductsList.vue"),
    ShopsMap: () => import("@/components/maps/ShopsMap.vue"),
  },
  data() {
    return {
      product: null,
      isShowLoader: false,
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    filteredShops() {
      return (
        this.product?.attributes?.shops?.data?.filter(
          (s) => s.attributes?.city?.data?.id === this.selectedCity?.id
        ) || []
      );
    },
    imageUrl() {
      if (this.product.attributes?.image?.data?.length) {
        return this.$helpers.getAbsolutePath(
          this.product.attributes?.image?.data[0]?.attributes?.url
        );
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    "$attrs.id": {
      handler() {
        this.isShowLoader = true;

        getProductById(this.$attrs?.id).then((product) => {
          this.product = product;
          this.isShowLoader = false;
        });
      },
    },
  },
  created() {
    getProductById(this.$attrs?.id).then((product) => {
      this.product = product;
      eventBus.$emit("gtm", {
        event: "view_item",
        type: "ecommerce",
        items: [this.product],
        category: "Каталог скидок",
        listName: "",
      });
    });
  },
  methods: {
    getDate(item) {
      const dateFromStr = new Date(item.attributes.action_end);
      return new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },
    getDateMob(item) {
      const dateFromStr = new Date(item.attributes.action_end);
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },
    getDiscount(item) {
      if (!item.attributes.best_price) {
        return "-" + parseInt(item.attributes.discount * 100) + "%";
      }
    },
    getBestPrice(item) {
      if (item.attributes.best_price) {
        return this.$i18n.locale === "ru"
          ? "Социальная цена"
          : "Әлеуметтік баға";
      }
      return item.attributes.start_price + "";
    },
  },
};
</script>
<style lang="scss">
.full-product {
  &__img {
    position: relative;
  }
  &__left {
    position: relative;
  }
  &__translate {
    position: absolute;
    bottom: 0;
    left: -50px;
    &.relative {
      position: relative;
    }
    &-this {
      position: relative;
      display: block;
      max-width: max-content;
      padding: 8px 6px 8px 42px;
      background: linear-gradient(226.03deg, #01b6eb 19.32%, #008bc9 70.95%);
      border-radius: 15px;
      font-family: "GT Walsheim Pro Medium";
      font-size: 20px;
      line-height: 23px;
      transform: rotate(-4deg);
      color: #fff;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        background-image: url("~@/assets/img/icons/book-icon.svg");
        width: 22px;
        height: 17px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    &-word {
      position: relative;
      display: inline-block;
      max-width: max-content;
      padding: 8px 40px 8px 12px;
      background: linear-gradient(226.03deg, #01b6eb 19.32%, #008bc9 70.95%);
      border-radius: 15px;
      font-family: "GT Walsheim Pro Medium";
      font-size: 20px;
      line-height: 23px;
      margin-left: 15px;
      transform: translateY(-5px);
      color: #fff;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        background-image: url("~@/assets/img/icons/ornament.svg");
        width: 20px;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

@media (max-width: 900px) {
  .full-product__img {
    padding-right: 0;
  }
}
@media (max-width: 770px) {
  .full-product__translate {
    left: 0;
  }
}

@media (max-width: 570px) {
  .full-product__translate {
  }
  .full-product {
    &__left {
      position: relative;
    }
    &__translate {
      position: absolute;
      bottom: 0;
      left: -20px;
      &-this {
        padding: 6px 5px 6px 27px;
        border-radius: 10px;
        font-family: "GT Walsheim Pro Medium";
        font-size: 14px;
        line-height: 14px;
        transform: rotate(-4deg);
        color: #fff;
        &::before {
          left: 5px;
          width: 14px;
          height: 11px;
        }
      }
      &-word {
        padding: 6px 20px 5px 6px;
        border-radius: 10px;
        font-size: 14px;
        line-height: 14px;
        margin-left: 10px;
        transform: translateY(-5px);
        color: #fff;
        &::before {
          content: "";
          right: 5px;
          width: 11px;
          height: 100%;
        }
      }
    }
  }

  .product-block__price-wrapper {
    position: relative;
    top: 10px;
  }
  .product-block__right {
    padding-bottom: 20px;
  }
}
</style>
